<template>
  <el-row class="warp">
    <el-col :span="24" class="warp-breadcrum">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"><b>首页</b></el-breadcrumb-item>
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>操作日志</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>

    <el-col :span="24" class="warp-main">
      <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
        <el-form :inline="true" :model="filters">
          <el-form-item>
            <el-input v-model="filters.userName" placeholder="用户名"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-on:click="getOperationlogs" icon="search">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-on:click="goReset" icon="search">重置</el-button>
          </el-form-item>
        </el-form>
      </el-col>

      <el-table :data="operationlogs" highlight-current-row v-loading="listLoading" @selection-change="selsChange"
                style="width: 100%;" border>
        <el-table-column type="selection" width="50" align="center"></el-table-column>
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column prop="userName" label="用户名" width="100" align="center" sortable></el-table-column>
        <el-table-column prop="story" label="历史记录" width="160"></el-table-column>
        <el-table-column prop="afterContent" label="日志内容" min-width="500"></el-table-column>
        <el-table-column prop="formatCreateTime" label="创建时间" width="180" align="center" sortable></el-table-column>
        <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="danger" @click="delOperationlog(scope.$index,scope.row)" size="small" v-if="permissionButton.del">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-col :span="24" class="table-footer">
        <el-button type="danger" size="small" @click="batchDeleteOperationlog" :disabled="this.sels.length===0" v-if="permissionButton.batchDel">批量删除</el-button>

        <!--<el-pagination layout="prev, pager, next" @current-change="handleCurrentChange" :page-size="pageSize" :total="total"-->
                       <!--style="float:right;">-->
        <!--</el-pagination>-->

        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10,15,20,25,50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total" style="float:right;">
        </el-pagination>

      </el-col>

    </el-col>
  </el-row>
</template>
<script>
  import {reqGetOperationlogList,reqDeleteOperationlog,reqBatchDeleteOperationlog} from '../../../api/system-api';
  import permission from '../../../common/permission';
  export default{
      data(){
          return{
              filters:{
                userName:''
              },
            operationlogs:[],
            total:0,
            pageNum:1,
            pageSize:15,
            listLoading:false,
            sels:[],
            permissionButton:new permission.PermissionButton,
          }
      },
    methods:{
      handleCurrentChange(val) {
        this.pageNum = val;
        this.currentPage = val;
        this.getOperationlogs();
      },
      handleSizeChange(val) {
        this.pageSize=val;
        this.getOperationlogs();
      },
      goReset(){
          this.filters.userName='';
          this.pageNum=1;
          this.getOperationlogs();
      },
      getOperationlogs() {
        let para = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          userName: this.filters.userName
        };
        this.listLoading = true;
        reqGetOperationlogList(para).then((res) => {
          this.total = res.data.data.total;
          this.operationlogs = res.data.data.records;
          this.listLoading = false;
        })
      },
      selsChange: function (sels) {
        this.sels = sels;
      },
      //删除
      delOperationlog: function (index, row) {
        this.$confirm('确认删除该记录吗?', '提示', {type: 'warning'}).then(() => {
          this.listLoading = true;
          let para = {id: row.id};
          reqDeleteOperationlog(para).then(() => {
            this.listLoading = false;
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.getOperationlogs();
          });
        }).catch(() => {
        });
      },
      batchDeleteOperationlog: function () {
        var ids = this.sels.map(item => item.id).toString();
        this.$confirm('确认删除选中记录吗？', '提示', {
          type: 'warning'
        }).then(() => {
          this.listLoading = true;
          let para = {ids: ids};
          reqBatchDeleteOperationlog(para).then(() => {
            this.listLoading = false;
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.getOperationlogs();
          });
        }).catch(() => {

        });
      },
    },mounted(){
      var permissionId = this.$route.query.permissionId;
      permission.getPermissionButton(permissionId,this.permissionButton);
      this.getOperationlogs();
    }
  }
</script>
